@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	background-color: $neutral-white;
	color: $neutral-black;

	max-width: 75rem;
	margin: auto;

	padding: 4rem 1rem;

	display: flex;
	justify-content: center;
	align-items: center;

	@include md-up {
		padding: 4rem 6rem 4rem 2rem;
	}
}

.grey {
	background-color: #f2f4f6;
}

.header_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	p {
		color: $solid-blue;
		text-transform: uppercase;
		@include body7;
		letter-spacing: 0.125rem;
		text-align: center;
		margin-bottom: 1rem;
	}

	h2 {
		@include subheading1;
		text-align: center;
		margin-bottom: 1.5rem;
	}

	@include md-up {
		p {
			display: inline-block;
			border-radius: 5px;
			padding: 0.25rem 0.5rem;
			@include subheading5;
			text-align: center;

			background: linear-gradient(91deg, rgba(1, 78, 168, 0.05) -14.01%, rgba(1, 59, 139, 0.05) 53.53%, rgba(1, 42, 109, 0.05) 115.67%);

			margin-bottom: 1.5rem;
		}

		h2 {
			@include heading5;
			text-align: center;
			margin-bottom: 1.5rem;
		}
	}
}

.item_list {
	list-style: none;

	display: flex;
	justify-content: center;
	align-items: center;

	padding: 1rem 0;
	gap: 2rem; // TODO consider doing no gap on mobile and instead justifying space between with a max-width

	@include md-up {
		padding: 1.5rem 2rem;
		gap: 4rem;
	}
}

.bottom_info {
	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	margin-top: 1.5rem;
}

.cta_button {
	margin-bottom: 1.5rem;

	@include md-up {
		margin-bottom: 2rem;
	}
}

.item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
		color: #8c8c8c;
		@include body7;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.05rem;
	}

	sup {
		font-size: 0.5em;
	}

	strong {
		color: $solid-blue;
		@include subheading1;
		margin: 0.25rem 0 0.75rem;
		display: block;
	}

	@include md-up {
		strong {
			@include heading5;
			margin: 0.25rem 0 0.75rem;
		}
	}
}

.divider {
	&::after {
		position: absolute;
		content: '';
		background: #d2d3d5;
		width: 1px;
		height: 50%;
		right: -1rem;

		@include md-up {
			right: -2rem;
		}
	}
}

.terms {
	@include body7;
	color: #898989;
	text-align: center;
	opacity: 0.7;
	line-height: 1.8;

	a {
		color: $solid-blue;
		text-decoration: underline;
		cursor: pointer;
	}
}

.mobile_image_container {
	img {
		max-height: 16rem;
	}

	@include md-up {
		display: none;
	}
}

.desktop_image_container {
	@include sm-down {
		display: none;
	}
}
