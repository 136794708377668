@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/colors';

.hero_heading {
	max-width: 13ch;
}

.subheading {
	max-width: 460px;
	font-size: 18px;

	@include xs-only {
		font-size: 16px;
	}
}

h2.x_heading.x_heading {
	max-width: 15ch;

	@include xs-only {
		text-align: left;
	}
}

.deskto_only_logo {
	position: absolute;
	right: 0;
	bottom: 20px;

	@include sm-down {
		display: none;
	}
}

.hero_heading_content.hero_heading_content {
	margin-top: 0;

	@include md-up {
		margin: 0 80px;
	}
}

.shop_card img {
	object-position: top center;
}
