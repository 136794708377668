@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	color: black;

	&.inverted {
		color: white;

		.cta_button > a {
			color: white;

			span:empty,
			span::before,
			span::after {
				background-color: white !important;
			}
		}
	}
}

.desktop_container {
	@include sm-down {
		display: none;
	}
}

.mobile_container {
	display: none;
	@include sm-down {
		display: unset;
	}
}

.cta_button {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

.desktop_list {
	list-style: none;
	display: flex;
	justify-content: space-between;
	align-items: center;

	max-width: 60rem;
	margin-inline: auto;
}

.logo_container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo {
	svg {
		height: clamp(1rem, 6.5vw, 2rem) !important;
		width: unset !important;
		max-height: unset !important;
		max-width: unset !important;
	}
}

.button {
	opacity: 0.28;

	&:hover,
	&:focus-visible {
		opacity: 1;
	}
}

.button_active {
	opacity: 1;
}

.quote_container {
	display: grid;
	grid-template-areas: 'content';
	justify-content: center;
	align-items: center;

	* {
		grid-area: content;
	}
}

.quote {
	opacity: 0;
	transition: all 0.5s ease-in-out;
	@include body1;
	text-align: center;
	max-width: 37ch;

	margin-top: 3.75rem;

	@include sm-down {
		opacity: 1;
		@include body4;
		margin-top: 2rem;
		margin-inline: auto;
	}
}

.mobile_slide {
	margin-bottom: 2rem;
}

.quote_active {
	opacity: 1;
}
