@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	padding: 3.75rem 1.5rem;
	background-color: $neutral-white;
	color: $neutral-black;

	@include md-up {
		padding: 7.5rem;
	}
}

.text {
	h2 {
		@include heading5;

		max-width: 25ch;

		margin-inline: auto;
		margin-bottom: 1rem;
	}

	p {
		@include body4;
		max-width: 42ch;
	}

	margin-bottom: 2.5rem;

	@include sm-up {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	@include md-up {
		h2 {
			@include heading4;
			margin-bottom: 1rem;
			margin-inline: auto;
		}

		p {
			@include body3;
			margin-inline: auto;
		}

		margin-bottom: 2.5rem;
	}
}

.cta,
.learn_more {
	margin-top: 1rem;
}

// .learn_more {
// 	display: none;

// 	@include sm-down {
// 		display: block;
// 	}
// }

.content_wrapper {
	display: flex;
	justify-content: center;
}

.content {
	display: inline-grid;
	grid-template-columns: 1fr;

	@include sm-up {
		grid-template-columns: 243px 1fr;
		gap: 2rem;
	}

	@include md-up {
		grid-template-columns: 303px 1fr;
		gap: 4rem;
	}
}

.image_wrapper {
	position: relative;
}

.image {
	img {
		max-height: 20rem;
	}

	@include sm-up {
		position: absolute;
		inset: 0;

		img {
			max-height: unset;
		}
	}
}

.list {
	list-style: none;
	padding: 0;
	margin: 0;

	p {
		@include body6;
	}

	@include sm-up {
		max-width: 24rem;
	}

	@include md-up {
		p {
			@include body3;
		}

		max-width: 32rem;
	}
}

.item {
	margin-top: 1rem;
	padding: 1rem;
	background-color: $neutral-6;
	border-radius: 10px;

	align-items: center;

	display: grid;
	grid-template-columns: 109px 1fr;
	gap: 1rem;

	transition: all 0.5s ease-in-out;

	opacity: 0;

	transform: translate3d(0, 25%, 0) scale(0.9);

	@include sm-up {
		&:first-child {
			margin-top: 0;
		}
	}

	@include md-up {
		padding: 1.25rem;
		grid-template-columns: 130px 1fr;
		gap: 1.5rem;

		margin-top: 2rem;
	}
}

.item_visible {
	opacity: 1;
	transform: translate3d(0, 0, 0) scale(1);
}
