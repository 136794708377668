@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/colors-2024';
@import 'styles/layout-tokens';
@import 'styles/mixins/fonts-2024';

.container {
	isolation: isolate;
	background-color: $neutral-8;

	color: $neutral-black;

	position: relative;

	@include md-up {
		background-color: $neutral-white;
		color: $neutral-white;
	}
}

.hero_container {
	height: 100vh;
	height: 100svh;
	height: 100dvh;

	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;

	@include md-up {
		flex-direction: row;
	}

	isolation: isolate;
}

.inner {
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	position: relative;
	@include md-up {
		flex-direction: row;
		justify-content: center;
		gap: min(9rem, 2vw);
		padding: 3.75rem;
	}
}

.shop_card_basic_inner {
	display: grid;
	grid-template-rows: 1fr 1fr;
	align-items: center;
	position: relative;

	@include xs-only {
		grid-template-rows: 100vw 1fr;
	}

	@include md-up {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: min(9rem, 2vw);
	}
}

.shop_card_basic_inner2 {
	display: grid;
	grid-template-rows: 100vw 1fr;
	align-items: center;
	position: relative;

	&.single_row {
		grid-template-rows: 1fr;
	}

	@include md-up {
		display: flex;
		flex-direction: column-reverse;

		.content {
			position: static;
			left: 0;
			isplay: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 90px;

			.cta {
				filter: invert(1);
			}

			h2.cheading {
				@include heading4;
				text-align: center;
				margin-bottom: 2rem;
				max-width: none;
				text-shadow: none;
				color: black;
			}
		}
	}
}

.content {
	padding: 3.75rem 2rem;

	display: flex;
	align-items: center;
	flex-direction: column;

	.cta {
		filter: invert(1);
	}

	h2 {
		@include heading5;
		text-align: center;
		max-width: 18ch;
		margin-bottom: 2rem;
	}

	@include md-up {
		left: 6.5rem;
		position: absolute;
		z-index: 30;
		align-items: flex-start;

		h2 {
			text-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
			@include heading4;
			text-align: left;
			margin-bottom: 2rem;
		}

		.cta {
			filter: unset;
		}
	}

	@include sm-down {
		grid-row: 2;
		align-items: center;
	}

	h3.headline3 {
		@include headline3;
		color: black;
		margin-bottom: 60px;
		text-align: center;

		@include sm-down {
			font-size: 40px;
			margin-bottom: 40px;
			color: white;
			text-align: center;
		}
	}

	.logos {
		margin-bottom: 60px;
		display: flex;
		justify-content: center;
		align-items: stretch;

		img {
			max-height: 40px;
		}

		@include sm-down {
			margin-bottom: 40px;
		}
	}
}

.hero_content {
	position: absolute;
	padding: 1.75rem 2rem;
	top: 21.5%;
	z-index: 30;

	display: flex;
	align-items: center;
	flex-direction: column;

	.cta {
		filter: invert(1);
	}

	@include md-up {
		top: auto;
		left: 5rem;
		padding: 0;
		align-items: flex-start;

		h1 {
			text-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
		}

		.cta {
			filter: unset;
		}
	}
}

.img {
	width: 100%;

	display: block;

	img {
		max-height: 400px;
		width: 100%;
	}

	@include md-up {
		img {
			max-height: unset;
			max-width: 513px;
		}
	}
}

.carousel_wrapper {
	position: relative;
	height: 100vw;
	overflow: hidden;
	background: $neutral-2;

	@include md-up {
		height: 523px;
		width: 100%;
	}
}

.carousel_simple {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background: $neutral-2;
}

.carousel {
	width: 100vw;
	padding: 0;
	list-style: none;
	position: relative;
	margin: 0;

	@include md-up {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		transform: translateY(20%);
	}
}

.carousel2 {
	width: 100vw;
	padding: 0;
	list-style: none;
	position: relative;
	margin: 0;

	@include md-up {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
	}
}

.base_plate {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10;
}

.bedroom {
	width: 100vw;
	position: absolute;

	@include md-up {
		width: 100%;
	}
}

.bedroom_hero {
	height: 100%;
	width: 100vw;
	position: absolute;

	* {
		object-fit: cover;
		height: 100%;
	}
}

.bedroom_img {
	img {
		height: 100%;
		object-position: 54.5% 50%;
	}

	@include md-up {
		img {
			object-position: 20% 50%;
		}
	}
}

.hubs {
	width: 100vw;
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;

	@include md-up {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 10;

		display: flex;
		align-items: flex-end;
		transform: translateY(20%);
	}
}

.hub {
	width: 100vw;
	position: absolute;
	transition: opacity 1s;

	@include md-up {
		width: 100%;
	}
}

.hub_hero {
	width: 100vw;
	height: 100vh;
	position: absolute;
	transition: opacity 1s;

	@include md-up {
		width: 100%;
		height: 100%;
	}

	img {
		height: 100%;
		object-position: 50% 80%;
	}
}

.shade {
	position: absolute;
	inset: 0;
	z-index: 20;
	background: linear-gradient(180deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 0%) 100%);
}

.superheader {
	font-size: 12px;
	text-align: center;
	letter-spacing: 3px;
	text-transform: uppercase;
	line-height: 1.1;
	font-weight: 400;
	margin-bottom: 15px;
	color: $brand-eight-blue;

	@include md-up {
		font-size: 16px;
		text-align: left;
		margin-left: 3px;
		color: $neutral-white;
	}
}

.header {
	@include heading5;
	margin-bottom: 1.5rem;
	text-align: center;

	@include md-up {
		@include heading2;
		text-align: left;
		margin-bottom: 2rem;
	}

	max-width: 18ch;
}

.anchor_link_wrapper {
	display: block;

	z-index: 300;
	position: absolute;
	bottom: 4rem;
	left: 50%;
	transform: translateX(-50%);
	width: 40px;
}

.shop_card_basic_image_container {
	width: 100%;
	height: 100%;
}

.shop_card_basic_image {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 75% 50%;
	}

	@include md-up {
		img {
			object-position: 50% 80%;
			max-height: 40rem;
		}
	}
}

h2.cheading.cheading {
	max-width: 11ch;
}

.buttons {
	display: flex;
	column-gap: 15px;

	.cta2 {
		background-color: #1a3ebb;
		color: white;
	}

	@include sm-down {
		.cta3 {
			background-color: white;
			color: black;
		}
	}
}

.pill {
	@include body7;
	color: $neutral-white;
	letter-spacing: 0.1875rem;
	text-transform: uppercase;
	font-weight: normal;
	margin-bottom: 15px;

	@include md-up {
		@include body6;
		text-align: left;
		margin-left: 3px;
		letter-spacing: 0.1875rem;
	}
}

.shop_card_higherdose_image {
	width: 100%;
	height: 100%;
	border-radius: 16px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	overflow: hidden;
}

.headline3 {
	@include headline3;
}
